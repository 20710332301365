<template>
	<div class="error error_404">
		<h1 class="h1 h1--dark">404 Error :(((</h1>
	</div>
</template>

<script>
export default {
	name: "Error404"
}
</script>